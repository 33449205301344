"use client"

import { useCallback, useEffect, useState } from "react"
import Image from "next/image"
import { ChevronLeft, ChevronRight } from "lucide-react"

interface SliderImage {
  src: string
  alt: string
  type: "image"
  heading: string
  description: string
}

interface SupervvisorHomeSliderProps {
  images: SliderImage[]
}

export function SupervvisorHomeSlider({ images }: SupervvisorHomeSliderProps) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isPaused, setIsPaused] = useState(false)

  const goToSlide = useCallback((index: number) => {
    setCurrentSlide(index)
  }, [])

  const goToNextSlide = useCallback(() => {
    setCurrentSlide((current) => (current + 1) % images.length)
  }, [images.length])

  const goToPrevSlide = useCallback(() => {
    setCurrentSlide((current) =>
      current === 0 ? images.length - 1 : current - 1
    )
  }, [images.length])

  useEffect(() => {
    if (images.length <= 1 || isPaused) return

    const timer = setInterval(goToNextSlide, 7000) // Change slide every 5 seconds

    return () => clearInterval(timer)
  }, [goToNextSlide, images.length, isPaused])

  return (
    <div
      className="relative overflow-hidden"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      {/* Navigation Arrows - Mobile Only */}
      {images.length > 1 && (
        <>
          <button
            onClick={goToPrevSlide}
            className="absolute bottom-[2px] left-0 z-10 rounded-full bg-white/80 p-1 shadow-md lg:hidden"
            aria-label="Previous slide"
          >
            <ChevronLeft className="h-6 w-6" />
          </button>
          <button
            onClick={goToNextSlide}
            className="absolute bottom-[2px] right-0 z-10 rounded-full bg-white/80 p-1 shadow-md lg:hidden"
            aria-label="Next slide"
          >
            <ChevronRight className="h-6 w-6" />
          </button>
        </>
      )}

      {/* Slides */}
      <div
        className="flex w-full transition-transform duration-500 ease-out"
        style={{
          transform: `translateX(-${currentSlide * (100 / images.length)}%)`,
          width: `${images.length * 100}%`,
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="flex w-full flex-shrink-0 items-center"
            style={{ width: `${100 / images.length}%` }}
          >
            <div className="slide-content flex flex-col">
              <div className="flex flex-row items-center gap-4 lg:flex-col lg:items-start">
                <Image
                  src={image.src}
                  alt={image.alt}
                  width={48}
                  height={48}
                  className="rounded-lg object-contain"
                  priority
                />
                <h3 className="text-2xl leading-8">{image.heading}</h3>
              </div>
              <p className="my-3 pb-2 text-sm font-normal text-slate-800 lg:text-base lg:leading-7">
                {image.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Dot Navigation */}
      {images.length > 1 && (
        <div className="mb-3 flex justify-center gap-2">
          {images.map((_, index) => (
            <button
              key={index}
              onClick={() => goToSlide(index)}
              className={`h-2 w-2 rounded-full transition-all ${
                currentSlide === index
                  ? "w-4 bg-primary"
                  : "bg-gray-300 hover:bg-gray-400"
              }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      )}
    </div>
  )
}
